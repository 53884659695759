<template>
  <div />
</template>
<script>
export default {
  created() {
    let loggedInUser = localStorage.getItem("loggedInUser");
    if (loggedInUser == "null") {
      loggedInUser = null;
    }
    if (!loggedInUser) {
      window.location.href = "/home.php";
    } else {
      this.$router.push("/dashboard");
    }
  },
};
</script>
